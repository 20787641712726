/* eslint-disable react/jsx-no-literals */
/** @jsx h */
import { h } from 'preact'
import { LinkWrapper } from '@nonsensebb/components'

import { PATHS, SEARCH_URL, TITLES } from '../../config'
import BackgroundTitle from '../atom/background-title'
import { SiteContentEntry } from '../molecule/site-content'

const NotFound = () => (
  <SiteContentEntry textMargin>
    <BackgroundTitle>{TITLES.NOT_FOUND}</BackgroundTitle>

    <p>
      {'O endereço que introduziu não foi encontrado, é possível que a página que procura tenha sido removida, o seu nome tenha sido alterado ou esteja temporariamente indisponível.'}
    </p>

    <p>
      {`Por favor experimente uma das seguintes opções:`}
    </p>

    <ul>
      <li>
        {`Verifique se o endereço está escrito correctamente`}
      </li>
      <li>
        Volte á <LinkWrapper href={PATHS.HOME}>pagina inicial</LinkWrapper> ou aos <LinkWrapper
        href={PATHS.ARCHIVES}>arquivos</LinkWrapper> e procure a pagina a partir daí
      </li>
      <li>
        Experimente <LinkWrapper href={SEARCH_URL}>fazer uma pesquisa usando o Google</LinkWrapper>
      </li>
    </ul>
  </SiteContentEntry>
)

NotFound.Footer = false

export default NotFound
